<template>
  <div class="question u-flex u-column u-between u-wrapper-panel u-pad-y-xl u-pad-x-sm u-pad-sm--md u-pad-t-md--md">
    <div class="u-full-width">
      <div class="u-center">
        <div class="question__counter t-c2 t-bold u-inline-block">
          {{formatNumber(data.index + 1)}}/{{formatNumber(total)}}
        </div>
      </div>
      <div class="t-p1 u-center u-white t-bold u-uppercase u-marg-t-sm u-pad-x-sm--md">
        {{ data.title }}
      </div>
    </div>
    <div v-if="$device.isDesktop" class="u-full-width">
      <div class="u-row u-center">
        <app-image class="u-w3of12" v-if="data.image.length" :id="data.image[0].id" />
      </div>
    </div>
    <div class="u-full-width">
      <div class="u-row u-evenly">
          <!-- @select="$emit('answer', answer)" -->
        <app-button-quizz
          @select="onAnswer(answer)"
          v-for="answer in answers"
          :isTrue="answer.value"
          :letter="answer.letter"
          :label="answer.label"
          :isResult="isResult"
          class="u-w3of12"
        />
      </div>
    </div>
    <transition name="t-fader--delayed">
      <div v-if="isResult" class="question__explanation u-bg-white u-radius u-w5of12 t-p2 u-gold u-pad-sm">
        {{ data.textWin }}
      </div>
    </transition>
  </div>
  
</template>

<script>
import AppButtonQuizz from '@/components/common/AppButtonQuizz'

const alphabet = ['A','B','C','D','E','F']

export default {
  components: {
    AppButtonQuizz
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    total: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      isResult: false
    }
  },

  computed: {
    answers () {
      return this.data.answers.map((answer, index) => {
        answer.letter = alphabet[index]
        return answer
      })
    }
  },

  methods: {
    onAnswer (answer) {
      this.isResult = true

      setTimeout(() => {
        this.$emit('answer', answer)
      }, 5000)
    },
    formatNumber (nbr) {
      return nbr < 10 ? '0' + nbr : nbr
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/viewport-sizer'

.question
  &__explanation
    viewportSizer('font-size', 22, 1920, 12px, 27px)
    viewportSizer('line-height', 26, 1920, 17px, 30px)
    position absolute
    font-weight: $weights.bold
    top 50%
    right calc(100% + 20px)

  &__counter
    background-color: $colors.gold
    color: $colors.white
    letter-spacing .1em
    border-radius 30px
    padding 7px 18px 3px
</style>