<template>
  <div class="high-scores u-white">
    <header-scoring v-if="!$device.isMobile" />
    <header-homepage v-else />
    <div class="high-scores__content u-row u-center u-marg-t-sm--sm">
      <div class="u-w10of12 u-left">
        <app-visible v-if="!$device.isMobile" class="homepage__name">
          Hello, {{ name }}
        </app-visible>
        <div class="u-marg-t-sm u-pad-b-lg">
          <app-visible v-if="!$device.isMobile" class="t-p1 u-inline-block u-marg-b-sm">
            High scores
          </app-visible>
          <bar class="u-marg-t-sm" :color="colors[i] ? colors[i] : ''" v-for="(score, i) in scores">
            <div class="high-scores__number u-w2of12 t-c1 t-love" :class="i < 3 ? 'u-white' : 'u-black'">
              {{ formatNumber(i + 1) }}
            </div>
            <div class="u-pad-l-xs--sm u-w6of12 t-p2 u-black">
              {{ score.name }}
            </div>
            <div class="u-w4of12 u-right">
              <div class="high-scores__card u-inline-block u-bg-white u-black t-c3">
                {{ score.score }} pts
              </div>
            </div>
          </bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppVisible from '@/components/common/AppVisible'
import HeaderScoring from '@/components/HeaderScoring'
import HeaderHomepage from '@/components/HeaderHomepage'
import Bar from '@/components/common/Bar'

import { api } from '@/store/interfaces/apiUser'
import formatName from '@/utils/formatName'

export default {
  computed: {
    // chapters () {
    //   return this.$store.getters['data/chapters']
    // },
    name () {
      return this.$store.getters['data/name']
    }
  },

  components: {
    AppVisible,
    HeaderScoring,
    HeaderHomepage,
    Bar
  },

  data () {
    return {
      colors: ['gold', 'silver', 'bronze'],
      scores: []
    }
  },

  created () {
    api.getFirstsScores().then(res => {
      this.scores = res.map(score => {
        return {
          score: score.number, name: formatName(score.user.email)
        }
      })
    })
  },

  methods: {
    formatNumber (nbr) {
      return nbr < 10 ? '0' + nbr : nbr
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/viewport-sizer'

.high-scores
  background-image url('/images/background--purple.jpg')
  background-size cover
  background-repeat repeat-y

  &__card
    padding 10px 16px 5px
    border-radius 20px

  &__number
    line-height 0.8em

  &__name
    font-family Phily
    font-weight: $weights.bold
    viewportSizer('font-size', 22, 1920, 12px, 27px)
    viewportSizer('line-height', 26, 1920, 17px, 30px)

  // &__content
  //   margin-top 90px
</style>