<template>
  <div class="header-scoring">
    <div class="u-row u-between u-top u-pad-lg u-white">
      <app-visible class="u-flex u-middle u-w3of12 u-left">
        <app-button-round :to="{name: 'Homepage'}" svg="arrow-left" />
      </app-visible>
      <app-visible class="u-center u-w6of12">
        <div class="header-scoring__title t-p1">
          Masterclass
        </div>
        <div class="header-scoring__subtitle t-p1">
          L’OREAL HAIR SCIENCE
        </div>
      </app-visible>
      <app-visible class="u-w3of12 u-right">
        <cartridge svg="crown" :text="`${score} pts`" />
      </app-visible>
    </div>
  </div>
</template>

<script>
import Cartridge from '@/components/common/Cartridge'
import AppButtonRound from '@/components/common/AppButtonRound'
import AppVisible from '@/components/common/AppVisible'

export default {
  components: {
    Cartridge,
    AppButtonRound,
    AppVisible
  },

  computed: {
    score () {
      return this.$store.getters['data/score']
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/mixins/mq'

.header-scoring
  &__title
    viewportSizer('font-size', 85, 1920)

    +mq($until: 'desktop')
      viewportSizer('font-size', 85, 1440)

    +mq($until: 'tablet')
      viewportSizer('font-size', 60, 767)
  &__subtitle
    margin-top 1em
    letter-spacing .14em
    viewportSizer('font-size', 18, 1920)

    +mq($until: 'desktop')
      viewportSizer('font-size', 24, 1440)

    +mq($until: 'tablet')
      viewportSizer('font-size', 16, 767)
</style>