import { api as apiCraft } from '@/store/interfaces/apiCraft'
import { api as apiUser } from '@/store/interfaces/apiUser'
import data from '@/assets/data'

import formatName from '@/utils/formatName'
// import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    chapters: [],
    currentScore: 0,
    loaded: false,
    user: {},
    name: '',
    score: 0,
    level: -1
  },

  getters: {
    chapters: state =>{
      return state.chapters
    },
    episodesCompleted: state =>{
      let nbr = 0
      state.chapters.forEach(chapter => {
        chapter.episodes.forEach(episode => {
          if (episode.isCompleted) {
            nbr++
          }
        })
      })
      return nbr
    },
    episodesTotal: state =>{
      return state.chapters.reduce((reducer, current) => {
        return reducer + current.episodes.length
      }, 0)
    },
    chapter: state => chapter => {
      return state.chapters.find(current => current.slug === chapter)
    },
    episode: (state, getters) => ({chapter, episode}) => {
      const currentChapter = getters.chapter(chapter)
      if (!currentChapter){ return null }
      return currentChapter.episodes.find(current => current.slug === episode)
    },
    nextEpisodeSlugs: (state, getters) => ({chapter, episode}) => {
      const currentChapter = getters.chapter(chapter)
      const currentEpisode = getters.episode({chapter, episode})

      if (currentEpisode.index === currentChapter.episodes.length - 1) {
        if (currentChapter.index === state.chapters.length - 1) {
          return {chapter: state.chapters[0].slug, episode: state.chapters[0].episodes[0].slug}
        }
        else {
          return {chapter: currentChapter.slug, episode: state.chapters[currentChapter.index + 1].episodes[0].slug}
        }
      }

      return {chapter: currentChapter.slug, episode: currentChapter.episodes[currentEpisode.index + 1].slug}
    },
    currentScore: state => {
      return state.currentScore
    },
    loaded: state =>{
      return state.loaded
    },
    score: state =>{
      return state.score
    },
    name: state =>{
      return state.name
    },
    level: state =>{
      return state.level
    }
  },

  actions: {
    // GET CRAFT CHAPTERS
    async chapters ({ commit, state, dispatch }) {
      return new Promise(resolve => {
        const { request, cache } = apiCraft.getChapters()
        // cache.then(res => {
        //   if (res !== null) {
        //     commit('chapters', formatChapters(res))
        //     commit('loaded', true)
        //     resolve()
        //   }
        // })

        request.then(res => {
          commit('chapters', formatChapters(res))
          commit('loaded', true)
          resolve()
        })
      }).then(() => {

        dispatch('episodesCompleted')
      })
    },

    // GET EPISODES COMPLETED
    async episodesCompleted ({ commit, state, getters }) {
      return new Promise(resolve => {
        const request = apiUser.getEpisodesCompleted(state.user.sub)
        request.then(res => {
          commit('seen', res)
          commit('level', {total: getters.episodesTotal, completed: getters.episodesCompleted})
          resolve()
        })
      })
    },

    // SET EPISODES AS COMPLETED
    async episodeComplete ({ commit, state, dispatch }, uid) {
      return new Promise(resolve => {
        const request = apiUser.setEpisodeCompleted(state.user.sub, uid)
        request.then(res => {
          dispatch('episodesCompleted')
          resolve()
        })
      })
    },

    // Get global scores
    async getScore ({ commit, state, dispatch }) {
      return new Promise(resolve => {
        const request = apiUser.getScore(state.user.sub)
        request.then(res => {
          if (!res) {
            dispatch('setScore', 0)
          }
          else {
            commit('score', res.number)
          }

          resolve()
        })
      })
    },

    async setScoreEnd ({ commit, state, dispatch }) {
      dispatch('setScore', state.currentScore + state.score + data.scoring.end)
      commit('reinitCurrentScore')
    },

    async setScore ({ commit, state, dispatch }, score) {
      return new Promise(resolve => {
        const request = apiUser.setScore(state.user.sub, score)
        request.then(res => {
          commit('score', res.number)
          resolve()
        })
      })
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    chapters (state, payload) {
      state.chapters = payload
    },
    right (state) {
      state.currentScore += data.scoring.right
    },
    wrong (state) {
      state.currentScore += data.scoring.wrong
    },
    reinitCurrentScore (state) {
      state.currentScore = 0
    },
    user (state, payload) {
      state.name = formatName(payload.name)
      state.user = payload
    },
    score (state, payload) {
      state.score = payload
    },
    seen (state, epSeens) {
      epSeens.forEach(epSeens => {
        let isFound = false

        state.chapters.forEach(chapter => {
          chapter.episodes.forEach(episode => {
            if (episode.uid === epSeens.uid) {
              episode.isCompleted = true
            }
          })
        })
      })
    },
    level (state, {total, completed}) {
      state.level = Math.floor(data.levels.length * (completed/total))
    }
  }
}

function formatChapters (chapters) {
  let globalEpisodeIndex = 0
  chapters.forEach((chapter, i) => {
    chapter.episodes.forEach((episode, j) => {
      episode.questions.forEach((question, k) => {
        question.index = k
      })
      episode.globalIndex = globalEpisodeIndex
      episode.isCompleted = false
      episode.index = j
      globalEpisodeIndex++
    })

    chapter.index = i
  })

  return chapters
}