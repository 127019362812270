<template>
  <div class="u-wrapper-panel" ref="container">
    <video ref="videoPlayer" class="video-js u-wrapper-panel" playsinline />
  </div>
</template>

<script>
import videojs from 'video.js'

export default {
  computed: {
    options () {
      return {
        autoplay: true,
        controls: true,
        loop: false,
        muted: false,
        preload: 'auto',

        language: 'fr',
        sources: [{
          type: "video/mp4",
          src: this.src
        }],
        // fluid: true
      }
    }
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    poster: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      width: 0,
      height: 0,
      currentTime: 0,
      isFullScreen: false
    }
  },
  mounted () {
    const _this = this

    videojs(this.$refs.videoPlayer, this.options, function () {
      _this.player = this
      _this.playerReady()
    })
  },
  unmounted () {
    if (this.player) {
      this.player.dispose()
      this.player.off('ended', this.onVideoEnded)
    }
  },
  methods: {
    playerReady () {
      this.player.on('ended', this.onVideoEnded)
    },
    onVideoEnded () {
      this.$emit('ended')
    },
    onResize () {
      // this.width = this.$refs.container.offsetWidth
      // this.height = this.$refs.container.offsetHeight

      // if (this.player) {
      //   this.player.width = this.width
      //   this.player.height = this.height
      // }
    },
    onPlayerTimeupdate (player) {
      // in seconds
      // this.currentTime = player.currentTime()
    },
    onPlayerReady (player) {
      this.player = player
      this.player.el_.appendChild(this.$refs.subtitles)
      this.onResize()
    }
  },
  beforeDestroy () {
    this.player.el_.removeChild(this.$refs.subtitles)
  }
}
</script>

<style lang="stylus">
@import '~@/../node_modules/video.js/dist/video-js.css'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

radius = 10px

.video-js
  font-size 20px
  height 100% !important
  
  video
    object-fit cover

  .vjs-control-bar
    background transparent
  // .vjs-play-control
  
  .vjs-big-play-button
    position absolute
    top calc(50% - 0.8em)
    left calc(50% - 0.8em)
    background: $colors.gold !important
    border none
    border-radius 50%
    height 1.5em
    width 1.5em

  .vjs-progress-control
    justify-content center

    .vjs-progress-holder
      width 30vw
      flex-grow 0
      border-radius radius
      
      .vjs-load-progress, .vjs-play-progress
        border-radius radius
      .vjs-load-progress > div
        border-radius radius
    
  .vjs-control
    color $colors.gold
  
  .vjs-remaining-time, .vjs-picture-in-picture-control, .vjs-fullscreen-control
    visibility hidden
    pointer-events none

  +mq($until: 'desktop')
    .vjs-remaining-time
      display none
</style>