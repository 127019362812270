<template>
  <div class="introduction u-full-height u-full-width u-row u-center u-middle">
    <div class="introduction__background u-row u-middle">
      <!-- <img class="u-full-width u-block" src="/images/wiggle.svg"> -->
      <wiggle class="u-full-width"/>
    </div>
    <bubble v-for="(picto, i) in pictos" :url="picto" class="introduction__picto" :class="'introduction__picto--' + i" />
    <div class="introduction__container u-relative u-w4of12 u-box-1by1 u-bg-white u-round">
      <div class="u-wrapper-panel u-row u-center u-middle">
        <div>
          <div>
            <img class="u-w5of12" src="/images/logo.jpg">
          </div>
          <div class="introduction__title t-p1 u-marg-t-md">
            Masterclass
          </div>
          <div class="introduction__subtitle t-p3">
            L’OREAL HAIR SCIENCE
          </div>
        </div>
      </div>
    </div>
    <transition name="t-fader">
      <app-popin-sign v-if="signIn" @close="signIn = false" />
    </transition>
    <div class="introduction__button u-row u-center">
      <app-button :to="{name: 'Homepage'}">
        Continue
      </app-button>
    </div>
  </div>
</template>

<script>
import Bubble from '@/components/common/Bubble'
import Wiggle from '@/components/common/Wiggle'
import AppPopinSign from '@/components/AppPopinSign'
import { AuthenticationState, AuthenticationProperties as auth0 } from 'vue-auth0-plugin'

export default {
  inject: ['auth'],

  components: {
    Bubble,
    Wiggle,
    AppPopinSign
  },

  data () {
    return {
      pictos: [
        '/images/picto__aqua.png',
        '/images/picto__heart.png',
        '/images/picto__particles.png'
      ],
      signIn: false
    }
  },

  created () {
    AuthenticationState.getAuthenticatedAsPromise().then(res => {
      if (res) {
        auth0.getTokenSilently().then(res => {
          this.$hub.emit('TOKEN_REFRESH', 'Bearer ' + res)
          this.$store.commit('data/user', auth0.user)
          this.$store.dispatch('data/getScore')
          this.$router.push({name: 'Homepage'})
        })
      }
    })
  },

  mounted () {
    if (!!this.$route.query.error_description) {
      this.signIn = true
    }

    if (this.$route.query.debug) {
      this.auth.logout()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.introduction
  background-image url('/images/background--blue.jpg')
  
  &__button
    position absolute
    bottom: $spacings.sm
    width 80%
    left 10%

  &__title
    font-size 56px
    margin-bottom 20px

    +mq($until: 'tablet')
      font-size 35px
      margin-bottom 10px

  &__subtitle
    letter-spacing .14em
  
  &__container
    +mq($until: 'tablet')
      width 80vw

  &__picto
    position absolute
    width 110px
    
    &--0
      top -40px
      left 30%
    &--1
      top 80%
      left 20%
    &--2
      top 65%
      left 80%

  &__background
    position absolute
    top calc(50% - 40vw)
    width 80vw
    left 10vw
    
    +mq($until: 'tablet')
      top calc(50% - 80vw)
      width 160vw
      left -40vw
    // height calc(50% - 40vw)

  </style>