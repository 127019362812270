<template>
  <app-visible class="card u-box-9by16 u-inline-block u-center u-white u-overflow-hidden">
    <router-link
      draggable="false"
      :to=" isEnabled ? {name: 'Episode', params: { chapter: chapter, episode: data.slug }} : '#'"
      class="u-block u-full-width"
    >
      <app-image v-if="background" :id="background" class="card__bg u-wrapper-panel u-fit-cover" :class="{'is-inverted': data.index % 2}" />
      <div class="u-wrapper-panel u-pad-md u-pad-sm--md u-white" :class="{'u-cursor-initial': !isEnabled}">
        <div>
          <bubble v-if="data.image.length" :factor="5" class="u-w7of12 u-w9of12--md" :id="data.image[0].id" />
        </div>
        <div class="u-marg-t-sm t-c2 u-black">
          {{ data.title }}
        </div>
        <div v-if="$device.isDesktop" class="u-marg-t-sm u-center t-p3">
          {{ data.subtitle }}
        </div>
      </div>
      <div class="card__top u-white u-center">
        <div v-if="data.isCompleted" class="card__top__round">
          <svg class="u-stroke-gold u-fill-transparent u-wrapper-panel" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#check"/>
          </svg>
        </div>
        <div v-else-if="$device.isDesktop" class="card__top__time t-c3 u-black">
          20:14
        </div>
      </div>
      <div class="card__bottom u-white u-center">
        <div v-if="data.isCompleted" class="card__bottom__complete t-p2 u-marg-b-sm">
          COMPLETED
        </div>
        <div class="card__bottom__nbr t-c1">
          {{ formatNumber(data.index + 1) }}
        </div>
      </div>
    </router-link>
  </app-visible>
</template>

<script>
import Bubble from '@/components/common/Bubble'
import AppVisible from '@/components/common/AppVisible'

export default {
  components: {
    Bubble,
    AppVisible
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    chapter: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: ''
    }
  },

  computed: {
    isEnabled () {
      return (typeof this.data.enabled !== 'boolean' || this.data.enabled)
    }
  },

  methods: {
    formatNumber (nbr) {
      return nbr < 10 ? '0' + nbr : nbr
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.card
  border-radius 30px
  // background: $gradients.purple
  
  &__bg.is-inverted
    transform scaleY(-1)

  &__top
    position absolute
    top: $spacings.sm
    right: $spacings.sm

    &__round
      background-color $colors.white
      border-radius 50%
      height 40px
      width 40px
      
      & > svg
        padding 10px

    &__time
      background-color $colors.white
      border-radius 30px
      padding 7px 12px 3px
    
  
  &__bottom
    position absolute
    width 100%
    bottom -50px

    +mq($until: 'desktop')
      bottom -30px

    &__complete
      letter-spacing .2em
      viewportSizer('font-size', 16, 1920, 12, 22)

    &__nbr
      font-size 170px
      font-family Love
      line-height 100px

      +mq($until: 'desktop')
        font-size 70px
        line-height 60px
</style>