<template>
  <div class="popin u-wrapper-panel u-row u-center u-middle">
    <div class="u-w4of12 u-w8of12--md u-relative u-radius" :class="innerClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    innerClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.popin
  &:before
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    content ''
    opacity .5
    background-color: $colors.black
</style>