<template>
  <app-popin innerClass="popin-sign u-pad-md">
    <!-- <div class="u-row u-center">
      <bubble v-if="!$device.isTablet" class="popin-sign__bubble" :id="data.image[0].id" />
    </div> -->
    <div class="t-p1 u-black">
      {{ $route.query.error_description }}
    </div>
    <div class="t-h2 u-row u-center u-marg-t-md">
      <app-button @click="$emit('close')" >
        Continue
      </app-button>
    </div>
  </app-popin>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'
import Bubble from '@/components/common/Bubble'
import Bar from '@/components/common/Bar'

import gsap from 'gsap'

import data from '@/assets/data'

export default {
  components: {
    AppPopin,
    Bubble,
    Bar
  },

  computed: {
    score() {
      return this.$store.getters['data/currentScore']
    }
  },

  data () {
    return {
      currentScoreTotal: 0,
      currentScore: 0,
      currentScoreEnd: 0
    }
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    next: {
      type: Object,
      default: () => {return {}}
    },
  },

  created () {
    this.eased = {
      scoreTotal: 0,
      score: 0,
      scoreEnd: 0
    }

    gsap.to(this.eased, {
      score: this.score,
      scoreTotal: this.score + data.scoring.end,
      scoreEnd: data.scoring.end,
      duration: 1.5,
      delay: 2,
      onUpdate: () => {
        this.currentScoreTotal = Math.round(this.eased.scoreTotal)
        this.currentScore = Math.round(this.eased.score)
        this.currentScoreEnd = Math.round(this.eased.scoreEnd)
      }
    })
  }
}
</script>

<style lang="stylus" scoped>
.popin-sign__card__text
  &--1
    font-size 16px
  &--2
    font-size 22px
  
</style>

<style lang="stylus">
@import '~@/styles/settings/variables'

.popin-sign
  background: $gradients.yellow
  
  // &__bubble
  //   width 160px
  //   margin-top -80px

</style>