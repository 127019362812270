<template>
  <component :is="to.name ? 'router-link' : 'div'" :to="to" class="button-round u-inline-block" >
    <div class="u-box-1by1 u-bg-white u-shadow u-round u-cursor-pointer">
      <div class="button-round__wrapper u-wrapper-panel">
        <div class="u-full-width u-full-height u-relative">
          <svg class="u-fit-contain u-wrapper-panel u-fill-gold" preserveAspectRatio="xMidYMid meet">
            <use :xlink:href="'#' + svg"/>
          </svg>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  props: {
    svg: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/variables'

.button-round
  width 60px

  &__wrapper
    padding 30%

  +mq($until: 'desktop')
    width 40px

    &__wrapper
      padding 30%

</style>