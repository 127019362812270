<template>
  <div class="episode u-full-width u-full-height">
    <!-- PANEL TO SWITCH PHONE DIRECTION -->
    <div v-if="isHorizontal" class="u-wrapper-panel u-row u-middle u-center">
      <div class="u-full-width">
        <div class="u-full-width u-row u-center">
          <img class="u-w4of12" src="/images/rotate.svg">
        </div>
        <div class="u-full-width u-row u-center u-marg-t-sm">
          <div class="u-w8of12 t-p1 u-white">
            For a better experience rotate your phone
          </div>
        </div>
      </div>
      <div class="episode__button u-row u-center">
        <app-button :isGhost="true" :to="{name: 'Homepage'}">
          Cancel
        </app-button>
      </div>
    </div>
    <template v-else>
      <transition name="t-fader--router">
        <video-player-full class="u-wrapper-panel" v-if="!isQuizz" @ended="isQuizz = true" :src="data.video[0].url" />
        <div v-else class="u-wrapper-panel">
          <img src="/images/temp__question.jpg" class="u-wrapper-panel u-fit-cover">
          <div class="u-full-height u-marg-l-6of12 u-w6of12 u-relative">
            <template v-for="(question, index) in data.questions">
              <transition name="t-fader--delayed">
                <question
                  v-if="indexQuestion === index"
                  :data="question"
                  :key="index"
                  @answer="onAnswer"
                  :total="data.questions.length"
                />
              </transition>
            </template>
          </div>
        </div>
      </transition>
      <transition name="t-fader--delayed">
        <app-popin-end @mounted="onEndPopin" :isCompleted="isCompleted" :next="nextEpisodeSlugs" v-if="isEpisodeEnded" :data="data" />
      </transition>
      <app-button-round :to="{name: 'Homepage'}" class="episode__btn-close" svg="home" />
    </template>
  </div>
</template>

<script>

import AppButtonRound from '@/components/common/AppButtonRound'
import VideoPlayerFull from '@/components/common/VideoPlayerFull'
import Question from '@/components/Question'
import AppPopinEnd from '@/components/AppPopinEnd'

export default {
  components: {
    VideoPlayerFull,
    Question,
    AppPopinEnd,
    AppButtonRound
  },

  data () {
    return {
      isQuizz: false,
      indexQuestion: 0,
      isEpisodeEnded: false,
      isCompleted: false
    }
  },

  computed: {
    isHorizontal () {
      return this.$device.isMobile && this.$device.height > this.$device.width
    },
    data () {
      return this.$store.getters['data/episode']({chapter: this.$route.params.chapter, episode: this.$route.params.episode})
    },
    nextEpisodeSlugs () {
      return this.$store.getters['data/nextEpisodeSlugs']({chapter: this.$route.params.chapter, episode: this.$route.params.episode})
    }
  },

  created () {
    this.onInit()
  },

  methods: {
    onInit() {
      this.isQuizz = false
      this.indexQuestion = 0
      this.isEpisodeEnded = false
      this.isCompleted = this.data.isCompleted
    },
    onAnswer (answer) {
      if (answer.value) {
        this.$store.commit('data/right')
      }
      else {
        this.$store.commit('data/wrong')
      }
      this.onNextQuesion()
    },
    onNextQuesion () {
      this.indexQuestion++

      if (this.indexQuestion >= this.data.questions.length) {
        this.isEpisodeEnded = true
      }
    },
    onEndPopin() {
      if (!this.data.isCompleted) {
        this.$store.dispatch('data/setScoreEnd')
        this.$store.dispatch('data/episodeComplete', this.data.uid)
      }
    }
  },

  watch: {
    $route (to, from) {
      if (to.name === 'Episode') {
        this.onInit()
      }
    }
  }
}

</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.episode
  background-image url('/images/background--blue.jpg')
  background-size cover
  background-repeat repeat-y

  &__button
    position absolute
    bottom: $spacings.sm
    width 80%
    left 10%

  &__btn-close
    position absolute
    top $spacings.md
    right $spacings.md

    +mq($until: 'desktop')
      top $spacings.sm
      right $spacings.sm
</style>