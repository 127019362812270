import { emitter } from '@/utils/emitter'

class Clock {
  constructor () {
    this._last = Date.now()

    this._tick = this._tick.bind(this)
    this.time = 0

    this._tick()
  }

  _tick () {
    const time = Date.now()
    const delta = time - this._last
    this.time += delta

    emitter.emit('clock:tick', {
      delta: delta,
      elapsed: this.time
    })

    this._last = time
    requestAnimationFrame(this._tick)
  }

  on (callback) {
    emitter.on('clock:tick', callback)
  }

  off (callback) {
    emitter.off('clock:tick', callback)
  }
}

export const clock = new Clock()
