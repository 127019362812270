export default function formatName (str) {
  let res = ''
  str = str.split('@')[0]
  str = str.split('.')
  str.forEach(s => {
    s = capitalizeFirstLetter(s)
    res += ' ' + s
  })

  return res
}

function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
