<template>
  <div ref="el" draggable="false" :class="[isGrabbing ? 'is-grabbing u-disable-selection' : '', hasDrag ? 'u-cursor-grab' : '', $device.isTouch ? 'u-overflow-x-scroll' : 'u-overflow-hidden' ]" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseEnd" @mouseleave="onMouseEnd" @touchstart="onMouseDown" @touchmove="onMouseMove" @touchend="onMouseEnd">
      <slot></slot>
  </div>
</template>

<script>
import { VirtualScroll } from '@/utils/VirtualScroll'

export default
{
  props: {
    containerWidth: {
      type: Number,
      default: 0
    },
    activeIndex: {
      type: Number,
      default: -1
    },
    isScroll: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isGrabbing: false,
      hasDrag: true
    } 
  },

  created () {
    this._mouseX = {
      start: 0,
      curr: 0,
      smooth: 0
    }
  },

  mounted() {
    this.$drag = this.$refs.el.querySelector('.js-drag')
    this.$nextTick(() => {
      this.onResize()
    })

    if (this.isScroll) {
      this.virtualScroll = new VirtualScroll()
      this.virtualScroll.onSmoothScroll(this.onScroll)
    }
  },

  beforeUnmount () {
    if (this.virtualScroll) {
      this.virtualScroll.onDestroy()
    }
  },

  methods: {
    onResize () {
      this._containerWidth = this.containerWidth === 0 ? this.$el.offsetWidth : this.containerWidth
      this._contentWidth = this.$drag.offsetWidth


      let width = 0
      for (let i = 0; i < this.$drag.children.length; i++) {
        // 30 is for margin
        width += this.$drag.children[i].offsetWidth + 30
      }

      this._contentWidth = width > this._contentWidth ? width : this._contentWidth

      this.hasDrag = this._contentWidth > this._containerWidth
    },

    onMouseDown(event) {
      if(this.$device.isTouch) {
        return
      }

      this.isGrabbing = true

      const x = event.pageX || (event.touches ? event.touches[0].clientX : 0)

      this._mouseX.start = x - this._mouseX.curr
    },

    onMouseMove(event) {
      if(!this.isGrabbing || this.$device.isTouch) {
        return
      }

      const x = event.pageX || (event.touches ? event.touches[0].clientX : 0)

      this._mouseX.curr = x - this._mouseX.start
    },

    onMouseEnd() {
      this.isGrabbing = false
    },

    onScroll ({ delta }) {
      this._mouseX.curr += delta
    },

    onUpdate() {
      if(this.virtualScroll) {
        this.virtualScroll.onUpdate()
      }

      if(!this.hasDrag) {
        this._mouseX.curr = 0
        this.$drag.style.transform = 'translateX(0) translateZ(0)'

        return
      }

      if(!this.$device.isTouch) {
        if(this._mouseX.curr > 0) {
            this._mouseX.curr = 0
        }

        if(this._mouseX.curr * -1 > (this._contentWidth - this._containerWidth)) {
            this._mouseX.curr = (this._contentWidth - this._containerWidth) * -1
        }

        this._mouseX.smooth += (this._mouseX.curr - this._mouseX.smooth) * 0.1

        const translation = this._mouseX.smooth.toFixed(2)

        this.$drag.style.transform = 'translateX(' + translation + 'px) translateZ(0)'
      }
    }
  }

}
</script>