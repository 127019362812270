<template>
  <div ref="el" class="visible" :isInViewport="{'is-visible': isInViewport}">
    <slot />
  </div>
</template>

<script>
import { mapper } from '@/utils/Maths'

export default {
  data () {
    return {
      isInViewport: false
    }
  },

  props: {
    staggerX: {
      props: Boolean,
      default: true
    },
    staggerY: {
      props: Boolean,
      default: true
    },
    staggerDelay: {
      props: Number,
      default: .8
    },
    margins: {
      type: Number,
      default: 100
    }
  },

  mounted () {
    this.testViewport()

  },

  methods: {
    testViewport () {
      this.onResize()
      if (this.staggerY) {
        this.isInViewport = true
      }
      if (!this.isInViewport) {
        setTimeout(this.testViewport, 200)
      }
    },

    onResize() {
      this.offset = this.$offset.absolute(this.$refs.el)
      this.width = this.$refs.el.offsetWidth
      this.height = this.$refs.el.offsetHeight

      const delayX = this.staggerX ? mapper(this.offset.left + this.width * .5, 0, this.$device.width, 0, this.staggerDelay) : 0
      const delayY = this.staggerY ? mapper(this.offset.top + this.height * .5, 0, this.$device.height, 0, this.staggerDelay) : 0

      this.$refs.el.style.transitionDelay = delayX + delayY + 's'

      this.onScroll()
    },

    onScroll() {
      if( this.isInViewport ) { return }

      this.isInViewport = this.getIsInViewport(this.offset.top - this.margins, Infinity)
    },

    getIsInViewport(offsetTop, height) {
      
      if( offsetTop + height > this.$device.scroll.top && offsetTop < this.$device.scroll.top + this.$device.height ) {
        return true
      }
      else {
        return false
      }
    }
  },

  watch: {
    isInViewport () {
      if (this.isInViewport) {
        this.$refs.el.classList.add('is-visible')
      }
    }
  }
}  
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'

.visible
  opacity 0
  transition opacity .75s linear

  &.is-visible
    opacity 1
</style>