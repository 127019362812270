import Apollo from '@/store/interfaces/Apollo'
import gql from 'graphql-tag'
// import { storage } from '@/utils/storage'

const apollo = new Apollo(process.env.VUE_APP_USER_API)
const graphqlClient = apollo.client

class APIUser {

  getEpisodesCompleted (id) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query allCraftObject ($id: String) {
            allCraftObject (auth0Id: $id) {
              uid
            }
          }
        `,
        variables: { id: id }
      })
      .then(res => {
        const result = res.data.allCraftObject

        resolve(result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })

    return request
  }

  getScore (id) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query allScore ($id: String) {
            allScore (auth0Id: $id) {
              number
            }
          }
        `,
        variables: { id: id }
      })
      .then(res => {
        const result = res.data.allScore[0]

        resolve(result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })

    return request
  }

  setEpisodeCompleted (id, uid) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.mutate({
        mutation: gql`
          mutation StoreCraftObject ($id: String!, $uid: String!) {
            storeCraftObject (auth0Id: $id, uid: $uid) {
              craftObject {
                uid
              }
            }
          }
        `,
        variables: { id: id, uid: uid }
      })
      .then(res => {
        const result = res.data

        resolve(result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })

    return request
  }

  setScore (id, score) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.mutate({
        mutation: gql`
          mutation StoreScore ($id: String!, $score: Int!) {
            storeScore (auth0Id: $id, number: $score) {
              score {
                number
              }
            }
          }
        `,
        variables: { id: id, score: score }
      })
      .then(res => {
        const result = res.data.storeScore.score

        resolve(result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })

    return request
  }

  getFirstsScores (length = 20, start = 0) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query allScore ($length: Int!, $start: Int!) {
            allScore (length: $length, start: $start, order: "DESC") {
              number
              user {
                email
              }
            }
          }
        `,
        variables: { length: length, start: start }
      })
      .then(res => {
        const result = res.data.allScore

        resolve(result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })

    return request
  }
}

export const api = new APIUser()