<template>
  <app-popin innerClass="popin-end u-pad-x-md u-pad-b-md">
    <div class="u-row u-center">
      <bubble v-if="!$device.isTablet" class="popin-end__bubble" :id="data.image[0].id" />
    </div>
    <template v-if="!isCompleted">
      <div class="t-p1 u-white t-bold u-pad-x-3of12 u-pad-x-0--md u-marg-t-sm">
        YOU FINISHED THIS EPISODE
      </div>
      <div class="u-center u-marg-t-sm">
        <div class="popin-end__card t-c2">
          +{{currentScoreTotal}}pts
        </div>
      </div>
      <bar class="u-marg-t-md u-marg-t-sm--md">
        <div class="popin-end__card__text--1 u-w9of12 t-p3 t-bold u-left">
          Episode completed
        </div>
        <div class="popin-end__card__text--2 u-w3of12 t-c3 u-right">
          +{{currentScoreEnd}}pts
        </div>
      </bar>
      <bar class="u-marg-t-sm u-marg-t-xs--md">
        <div class="popin-end__card__text--1 u-w9of12 t-p3 t-bold u-left">
          Right anwsers
        </div>
        <div class="popin-end__card__text--2 u-w3of12 t-c3 u-right">
          +{{ currentScore }}pts
        </div>
      </bar>
    </template>
    <template v-else>
      <div class="t-p1 u-white t-bold u-pad-x-1of12 u-marg-t-sm">
        YOU HAVE ALREADY FINISHED THIS EPISODE
      </div>
    </template>
    <div class="t-h2 u-row u-between u-marg-t-md u-marg-t-sm--md">
      <app-button :to="{name: 'Homepage'}" :isGhost="true" >
        Return to Hub
      </app-button>
      <app-button :to="{name: 'Episode', params: { chapter: next.chapter, episode: next.episode }}">
        Next episode
      </app-button>
    </div>
  </app-popin>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'
import Bubble from '@/components/common/Bubble'
import Bar from '@/components/common/Bar'

import gsap from 'gsap'

import data from '@/assets/data'

export default {
  components: {
    AppPopin,
    Bubble,
    Bar
  },

  computed: {
    score() {
      return this.$store.getters['data/currentScore']
    }
  },

  data () {
    return {
      currentScoreTotal: 0,
      currentScore: 0,
      currentScoreEnd: 0
    }
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    next: {
      type: Object,
      default: () => {return {}}
    },
    isCompleted: {
      type: Boolean,
      default: false
    },
  },

  created () {
    this.eased = {
      scoreTotal: 0,
      score: 0,
      scoreEnd: 0
    }

    gsap.to(this.eased, {
      score: this.score,
      scoreTotal: this.score + data.scoring.end,
      scoreEnd: data.scoring.end,
      duration: 1.5,
      delay: 2,
      onUpdate: () => {
        this.currentScoreTotal = Math.round(this.eased.scoreTotal)
        this.currentScore = Math.round(this.eased.score)
        this.currentScoreEnd = Math.round(this.eased.scoreEnd)
      },
      onComplete: () => {
        this.$emit('mounted')
      }
    })
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.popin-end__card__text
  &--1
    font-size 16px
    +mq($until: 'desktop')
      font-size 12px
  &--2
    font-size 22px
    +mq($until: 'desktop')
      font-size 14px
  
</style>

<style lang="stylus">
@import '~@/styles/settings/variables'

.popin-end
  background: $gradients.yellow
  
  &__bubble
    width 160px
    margin-top -80px

  &__card
    display inline-block
    background-color: $colors.white
    color: $colors.gold
    border-radius 30px
    padding 7px 15px 4px
</style>
