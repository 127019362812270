<template>
  <div>
    <div class="u-row u-center u-middle">
      <div class="t-c1 u-w4of12 u-marg-y-sm">
        .t-c1
      </div>
      <div class="t-c2 u-w4of12 u-marg-y-sm">
        .t-c2
      </div>
      <div class="t-c3 u-w4of12 u-marg-y-sm">
        .t-c3
      </div>
      <div class="t-p1 u-w4of12 u-marg-y-sm">
        .t-p1
      </div>
      <div class="t-p2 u-w4of12 u-marg-y-sm">
        .t-p2
      </div>
      <div class="t-p3 u-w4of12 u-marg-y-sm">
        .t-p3
      </div>
    </div>
    <div class="u-row u-center u-middle">
      <div class="t-c2 u-w4of12 u-bg-gold">
        gold
      </div>
      <div class="t-c2 u-white u-w4of12 u-bg-black">
        black
      </div>
      <div class="t-c2 u-w4of12 u-bg-white">
        white
      </div>
    </div>
    <div class="u-row u-center u-middle">
      <div class="u-w4of12 u-pad-y-sm u-bg-gold">
        <app-button>
          plop
        </app-button>
      </div>
      <div class="u-w4of12 u-pad-y-sm u-bg-black">
        <app-button :isGhost="true">
          plop
        </app-button>
      </div>
      <div class="u-w4of12 u-pad-y-sm">
        <app-button :isGold="true">
          plop
        </app-button>
      </div>
    </div>
    <div class="u-row u-center u-middle">
      <div class="u-w4of12 u-pad-y-sm u-bg-black">
        <app-button-quizz :isTrue="true" letter="A" label="first btn" />
      </div>
      <div class="u-w4of12 u-pad-y-sm u-bg-black">
        <app-button-quizz :isTrue="false" letter="B" label="second btn" />
      </div>
    </div>
    <div class="u-row u-center u-middle">
      <div class="u-w4of12 u-pad-y-sm u-bg-gold u-center">
        <app-button-round svg="arrow-left" />
      </div>
      <div class="u-w4of12 u-pad-y-sm u-bg-gold u-center">
        <cartridge svg="arrow-left" text="plopplop" />
      </div>
      <div class="u-w4of12 u-pad-y-sm u-bg-gold u-center">
        <bubble class="u-w2of12" url="/images/temp.png" />
      </div>
    </div>
    <div class="u-row u-center u-middle">
      <!-- <card class="u-w2of12" /> -->
    </div>
  </div>
</template>

<script>
import AppButtonQuizz from '@/components/common/AppButtonQuizz'
import AppButtonRound from '@/components/common/AppButtonRound'
import Cartridge from '@/components/common/Cartridge'
import Bubble from '@/components/common/Bubble'
import Card from '@/components/common/Card'

export default {
  components: {
    AppButtonQuizz,
    AppButtonRound,
    Cartridge,
    Bubble,
    Card
  }
}
</script>