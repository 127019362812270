<template>
  <div class="wiggle u-box-1by1" />
</template>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.wiggle
  background: $gradients.yellow
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%
  animation morph 6s ease-in-out-quad infinite
  transform translateZ(0)

  &:before,
  &:after
    content ''
    border-radius 42% 58% 70% 30% / 45% 45% 55% 55%
    box-shadow 5px 5px 89px rgba(0, 102, 255, 0.21)
    will-change border-radius, transform, opacity
    animation-delay 1.5s
    background: $gradients.yellow
  
  &:before
    animation morph 5s ease-in-out-quad infinite
  
  &:after
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    animation morph 4s ease-in-out-quad infinite
    animation-delay 3s
    opacity .89
    line-height 120px
    text-indent -21px

@keyframes morph
  0%, 100% 
    border-radius 42% 58% 70% 30% / 45% 45% 55% 55%
    transform translate3d(0,0,0) rotateZ(0.01deg)
  34%
    border-radius 70% 30% 46% 54% / 30% 29% 71% 70%
    transform translate3d(0,5px,0) rotateZ(0.01deg)
  50%
    // opacity .89
    transform translate3d(0,0,0) rotateZ(0.01deg)
  67%
    border-radius 100% 60% 60% 100% / 100% 100% 60% 60% 
    transform translate3d(0,-3px,0) rotateZ(0.01deg)
</style>