import mixin from 'vue'
import { clock } from './clock'

export default {
  mounted () {
    clock.on(this.onUpdate)
    this.$hub.on('resize', this.onResize)
    this.$hub.on('scroll', this.onScroll)
  },
  beforeUnmount () {
    clock.off(this.onUpdate)
    this.$hub.off('resize', this.onResize)
    this.$hub.off('scroll', this.onScroll)
  },
  methods: {
    onUpdate () {},
    onResize () {},
    onScroll () {}
  }
}
