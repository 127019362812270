<template>
  <component :is="to.name ? 'router-link' : 'div'" :to="to" class="cartridge u-inline-block u-bg-white">
    <div class="t-c2 u-row u-evenly u-bottom u-black">
      <div v-if="svg" class="cartridge__svg u-relative">
        <svg class="u-fill-gold u-wrapper-panel" preserveAspectRatio="xMidYMid meet">
          <use :xlink:href="'#' + svg"/>
        </svg>
      </div>
      <div>
        {{ text }}
      </div>
    </div>
  </component>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  props: {
    svg: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}

</script>

<style lang="stylus">
.cartridge
  border-radius 60px
  padding 10px 30px

  &__svg
    height 1.2em
    width 1.2em
    margin-right .6em
</style>