<template>
  <div class="episodes u-w10of12 u-left u-row u-marg-t-sm u-pad-b-xs u-middle">
    <app-visible class="t-p1 u-w9of12--sm">
      {{ data.title }}
    </app-visible>
    <app-visible class="u-w3of12--sm u-marg-l-md u-marg-l-0--sm u-right">
      <div class="episodes__count t-c2 u-inline-block">
        {{nbrComplete}}/{{ data.episodes.length }}
      </div>
    </app-visible>
  </div>
  <div class="u-w12of12 u-left u-marg-t-sm u-marg-b-md">
    <app-drag class="u-nowrap u-pad-b-sm--sm" :isScroll="!$device.isMobile">
      <div class="js-drag u-pad-x-1of12">
        <card
          v-for="episode in data.episodes"
          :data="episode"
          :chapter="data.slug"
          :background="data.image.length ? data.image[0].id : ''"
          class="episodes__card u-wrap u-marg-r-sm js-drag-item"
          :key="episode.id"
          :class="{'episodes__card--disabled': !episode.enabled}"
        />
      </div>
    </app-drag>
  </div>
</template>

<script>
import Card from '@/components/common/Card'
import AppDrag from '@/components/common/AppDrag'
import AppVisible from '@/components/common/AppVisible'

export default {
  components: {
    Card,
    AppDrag,
    AppVisible
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    }
  },

  computed: {
    nbrComplete () {
      return this.data.episodes.filter(chapter => chapter.isCompleted).length
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
// @import '~@/styles/settings/mixins/mq'

.episodes    
    &__card
      min-width 160px
      width 18%
    
      &--disabled
        opacity 0.4 !important
        
    &__count
      background-color: $colors.white
      color: $colors.black
      border-radius 30px
      padding 8px 20px 4px
</style>