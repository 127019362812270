export default {
	namespaced: true,

	state: {
		options: {},
	},

	getters: {
		isLoading(state) {
			return state.isLoading;
		}
	},

	actions: {
		showPrehome( { commit, state } ) {
			commit('showPrehome');
		}
	},

	mutations: {
		loadingStart(state) {
			state.isLoading = true;
		}
	}
}