<template>
  <app-visible class="bar u-pad-y-sm u-pad-x-md u-pad-sm--sm u-row u-middle" :class="`bar--${color}`">
    <slot></slot>
  </app-visible>
</template>

<script>
import AppVisible from '@/components/common/AppVisible'

export default {
  components: {
    AppVisible
  },

  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'


.bar
  box-shadow inset 0px -8px 25px 10px rgba($colors.black, .15)
  border-radius 20px
  background: $colors.white

  +mq($until: 'desktop')
    padding 10px 20px !important
  
  &--gold
    background: $gradients.yellow
  
  &--silver
    background linear-gradient(28.2deg, #FFFFFF -4.22%, #C9C9C9 96.32%)
  
  &--bronze
    background linear-gradient(28.2deg, #FFBEBE -4.22%, #DF9E61 96.32%)
</style>