<template>
  <div ref="btn" class="button-quizz u-center">
    <div class="">
      <div
        class="button-quizz__btn u-full-width u-box-1by1 u-cursor-pointer u-inline-block"
        :style="{transform: `scale(${scale}, ${scale}) translateZ(0)`}"
      >
        <div class="button-quizz__btn__letter t-bold t-p1 u-bg-white u-gold u-wrapper-panel u-round u-row u-middle u-center">
          {{ letter }}
        </div>
        <div
          class="button-quizz__btn__letter button-quizz__btn__letter--over u-white u-round t-bold t-p1 u-wrapper-panel u-row u-middle u-center"
          :style="{
            opacity: isResult ? 1 : 0,
            backgroundColor: isTrue ? '#3DCD7F' : '#FF7474'
          }"
        >
          {{ letter }}
        </div>
      </div>
    </div>
    <div class="button-quizz__label u-marg-t-sm u-marg-t-xs--sm t-p3 t-bold u-uppercase u-black--md">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    letter: {
      type: String,
      default: 'A'
    },
    label: {
      type: String,
      default: 'Potatoe'
    },
    isTrue: {
      type: Boolean,
      default: false
    },
    isResult: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    scale () {
      return this.isDown ? .95 : 1
    }
  },

  data () {
    return {
      isDown: false,
    }
  },

  mounted () {
    this.$refs.btn.addEventListener(this.$device.pointerdown, this.onPointerdown)
    window.addEventListener(this.$device.pointerup, this.onPointerup)
  },

  beforeUnmount () {
    this.$refs.btn.addEventListener(this.$device.pointerdown, this.onPointerdown)
    window.removeEventListener(this.$device.pointerup, this.onPointerup)
  },

  methods: {
    onPointerdown () {
      this.isDown = true
    },

    onPointerup () {
      if (this.isDown) {
        this.$emit('select')
        this.isDown = false
      }
    }
  }
}
</script>

<!-- scoped style is to overwrite default style -->
<style lang="stylus" scoped>
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.button-quizz
  &__btn
    max-width 130px
    transition transform .2s ease-in-out-quad

    +mq($until: 'desktop')
      max-width 10vw

    &__letter
      font-size 65px
      box-shadow: inset 0px -11px 18px -6px rgba($colors.black, .3)

      +mq($until: 'desktop')
        font-size 220%
      
      &--over
        transition opacity .3s linear
        box-shadow: inset 0px -11px 18px -2px rgba($colors.white, .2)
  
  &__label
    viewportSizer('font-size', 22, 1920)

    +mq($until: 'desktop')
      font-size 12px

</style>

