<template>
  <div style="position: fixed; width: 0; height: 0; overflow: hidden; top: -99px">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="arrow-left" viewBox="0 0 20 19">
          <title>Arrow Left</title>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8158 7.4854H6.29139L10.6311 3.14569C11.3504 2.42487 11.3504 1.25887 10.6311 0.539515C9.91174 -0.179838 8.74574 -0.179838 8.02491 0.539515L0.539515 8.02491C-0.179838 8.74426 -0.179838 9.91174 0.539515 10.6311L8.02491 18.1165C8.38606 18.4762 8.85629 18.656 9.328 18.656C9.80118 18.656 10.2714 18.4762 10.6311 18.1165C11.3504 17.3971 11.3504 16.2311 10.6311 15.5103L6.29139 11.1706H17.8158C18.8329 11.1706 19.6584 10.3451 19.6584 9.328C19.6584 8.31088 18.8329 7.4854 17.8158 7.4854Z"/>
        </symbol>
        <symbol id="arrow-right" viewBox="0 0 10 11">
          <title>Arrow right</title>
          <path d="M9.48635 6.25682L1.22457 10.3877C0.661957 10.669 -3.23503e-08 10.2599 -5.98455e-08 9.6309L-4.2098e-07 1.36911C-4.48475e-07 0.740092 0.661956 0.33098 1.22456 0.612285L9.48635 4.74318C10.11 5.05501 10.11 5.945 9.48635 6.25682Z"/>
        </symbol>
        <symbol id="check" viewBox="0 0 19 13">
          <title>Check</title>
          <path d="M1.5 6.5L7 12L18 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </symbol>
        <symbol id="sound" viewBox="0 0 44 34">
          <title>Sound</title>
          <path d="M20.9011 0.149856L7.95649 8.39485C7.63626 8.6019 7.26542 8.71601 6.88445 8.72475H2.06125C1.51465 8.72475 0.99013 8.94192 0.603652 9.3284C0.217173 9.71488 0 10.2394 0 10.786V21.7516C0 22.2982 0.217173 22.8227 0.603652 23.2092C0.99013 23.5957 1.51465 23.8129 2.06125 23.8129H7.62662C8.06694 23.8092 8.49805 23.9385 8.86337 24.1842L20.8598 32.8414C21.0126 32.9449 21.1907 33.0052 21.3747 33.0162C21.5592 33.0277 21.7432 32.9886 21.9075 32.9049C22.0717 32.8207 22.2107 32.6937 22.3092 32.5373C22.4076 32.3813 22.4624 32.2014 22.4674 32.0169V1.01571C22.4647 0.834425 22.4145 0.656368 22.3207 0.500396C22.2273 0.344884 22.0943 0.216509 21.9356 0.12817C21.7764 0.040291 21.5969 -0.00387886 21.4152 0.000267199C21.2334 0.003948 21.0563 0.0554783 20.9008 0.150255L20.9011 0.149856Z"/>
          <path d="M35.9472 16.6383C35.9638 14.4759 35.2829 12.3654 34.0057 10.6207C32.7284 8.87556 30.923 7.58863 28.8567 6.95047C28.3096 6.79725 27.7248 6.86765 27.2298 7.14602C26.7352 7.42438 26.3713 7.8877 26.218 8.43436C26.0648 8.98141 26.1352 9.56621 26.4136 10.0612C26.692 10.5558 27.1553 10.9198 27.7023 11.073C28.8958 11.4594 29.9366 12.214 30.6745 13.229C31.4125 14.2436 31.81 15.466 31.81 16.7208C31.81 17.9756 31.4125 19.1979 30.6745 20.2125C29.9365 21.2275 28.8957 21.982 27.7023 22.3686C27.1957 22.5301 26.7729 22.8844 26.5263 23.3551C26.2792 23.8258 26.2277 24.3751 26.3827 24.8836C26.5152 25.3041 26.7789 25.6708 27.135 25.9308C27.4911 26.1903 27.9208 26.3287 28.3616 26.3265H28.9804C31.0265 25.6722 32.8089 24.3774 34.0631 22.6328C35.3178 20.8885 35.9781 18.7869 35.9472 16.6386L35.9472 16.6383Z"/>
          <path d="M31.3715 0.604394C30.8465 0.462226 30.2875 0.528937 29.8113 0.790271C29.3346 1.05161 28.9776 1.48733 28.8157 2.00582C28.7338 2.26577 28.7057 2.53907 28.7324 2.81007C28.7591 3.08107 28.8405 3.34378 28.9716 3.58258C29.1023 3.82137 29.2803 4.03118 29.4943 4.19958C29.7087 4.36751 29.9544 4.49082 30.2171 4.56167C32.8801 5.21869 35.2497 6.74212 36.9521 8.89265C38.6549 11.0432 39.5945 13.6989 39.6225 16.442C39.6506 19.1846 38.7663 21.8592 37.109 24.0447C35.4508 26.2298 33.1139 27.8018 30.4647 28.5136C29.9181 28.5955 29.4262 28.8914 29.0978 29.3358C28.7688 29.7803 28.6303 30.3375 28.7127 30.8841C28.7946 31.4307 29.0904 31.9226 29.5349 32.251C29.9793 32.5795 30.5365 32.718 31.0832 32.6361H31.7016C35.0999 31.5774 38.0647 29.4472 40.1532 26.5646C42.2411 23.6812 43.3398 20.2001 43.2855 16.6407C43.2814 13.0437 42.1206 9.54318 39.9755 6.65602C37.8305 3.76839 34.8142 1.64695 31.3716 0.604311L31.3715 0.604394Z"/>
        </symbol>
        <symbol id="home" viewBox="0 0 35 35">
          <title>Home</title>
          <path d="M34.0566 15.2233L34.054 15.2206L19.7767 0.943947C19.1682 0.335121 18.3591 0 17.4984 0C16.6378 0 15.8287 0.335121 15.2199 0.943947L0.950124 15.2134C0.945318 15.2182 0.940244 15.2233 0.935705 15.2281C-0.31399 16.485 -0.311854 18.5243 0.941846 19.778C1.51462 20.3511 2.27085 20.6827 3.07968 20.7177C3.11279 20.7209 3.1459 20.7225 3.17928 20.7225H3.74805V31.229C3.74805 33.3084 5.43995 35 7.5193 35H13.105C13.6714 35 14.1304 34.5407 14.1304 33.9746V25.7373C14.1304 24.7886 14.9024 24.0168 15.8511 24.0168H19.1457C20.0945 24.0168 20.8662 24.7886 20.8662 25.7373V33.9746C20.8662 34.5407 21.3252 35 21.8916 35H27.4773C29.5569 35 31.2485 33.3084 31.2485 31.229V20.7225H31.7762C32.6366 20.7225 33.4457 20.3874 34.0548 19.7783C35.3098 18.5227 35.3103 16.4797 34.0566 15.2233Z"/>
        </symbol>
        <symbol id="student" viewBox="0 0 46 31">
          <title>student</title>
          <path d="M22.1311 21.6177L21.7668 21.4623L11.6893 17.1601C10.479 16.6436 9.49805 17.2918 9.49805 18.6075V23.2626C9.49805 27.0227 15.3172 30.0711 22.4956 30.0711C29.6746 30.0711 35.4941 27.0227 35.4941 23.2626V18.6075C35.4941 17.2917 34.5132 16.6436 33.3028 17.1601L23.2241 21.4623C23.2241 21.4623 23.0609 21.5321 22.8598 21.6177C22.6587 21.7033 22.3321 21.7033 22.131 21.6177H22.1311Z"/>
          <path d="M20.304 0.387955L0.907725 8.66765C-0.302575 9.18409 -0.302575 10.0216 0.907725 10.5386L20.304 18.8191C21.5143 19.3356 23.4762 19.3356 24.686 18.8191L35.4935 14.2052L44.0828 10.5386C45.2926 10.0221 45.2926 9.18457 44.0828 8.66765L24.6865 0.387955C23.4762 -0.129318 21.5143 -0.129318 20.304 0.387955Z"/>
          <path d="M42.7578 9.10254H44.6146V23.002H42.7578V9.10254Z"/>
          <path d="M45.0019 24.4946C45.0019 25.0111 44.4127 25.4299 43.6861 25.4299C42.9594 25.4299 42.3711 25.0111 42.3711 24.4946V22.2194C42.3711 21.7026 42.9594 21.2842 43.6861 21.2842C44.4127 21.2842 45.0019 21.7022 45.0019 22.2194V24.4946Z"/>
        </symbol>
        <symbol id="out" viewBox="0 0 30 34">
          <title>out</title>
          <path d="M18.7534 18.2365C18.0623 18.2365 17.5037 18.865 17.5037 19.6392V25.2507C17.5037 26.0236 16.9438 26.6534 16.2539 26.6534H12.5048V5.61117C12.5048 4.41316 11.8249 3.34281 10.8028 2.94442L10.4327 2.80546H16.2539C16.9438 2.80546 17.5037 3.43529 17.5037 4.20844V8.41689C17.5037 9.19107 18.0623 9.81961 18.7534 9.81961C19.4444 9.81961 20.003 9.19107 20.003 8.41689V4.20844C20.003 1.8882 18.3209 0 16.2539 0H2.81965C2.77205 0 2.73223 0.0238883 2.68601 0.0308235C2.62583 0.0251726 2.56839 0 2.5073 0C1.12884 0 0.0078125 1.25811 0.0078125 2.80546V28.0561C0.0078125 29.2541 0.687659 30.3245 1.70983 30.7229L9.23071 33.5371C9.48562 33.6254 9.73917 33.6675 10.0055 33.6675C11.384 33.6675 12.5048 32.4092 12.5048 30.8618V29.4591H16.2539C18.3209 29.4591 20.003 27.5709 20.003 25.2507V19.6392C20.003 18.865 19.4444 18.2365 18.7534 18.2365Z"/>
          <path d="M29.6345 13.0362L24.6356 7.42508C24.2784 7.02386 23.7409 6.90313 23.2736 7.12044C22.8075 7.338 22.5025 7.84993 22.5025 8.41682V12.6253H17.5038C16.8138 12.6253 16.2539 13.2536 16.2539 14.028C16.2539 14.8024 16.8138 15.4307 17.5038 15.4307H22.5025V19.6392C22.5025 20.2061 22.8075 20.718 23.2736 20.9355C23.7409 21.1529 24.2784 21.0321 24.6356 20.6312L29.6345 15.0197C30.1231 14.4713 30.1231 13.5846 29.6345 13.0362Z"/>
        </symbol>
        <symbol id="crown" viewBox="0 0 44 32">
          <title>crown</title>
          <path d="M42.4121 7.18394C41.9044 6.75056 41.1879 6.65923 40.5875 6.95151L31.4007 11.4239L22.7806 1.48511C22.4618 1.11745 21.999 0.90625 21.5123 0.90625C21.0257 0.90625 20.563 1.11745 20.2441 1.48511L11.624 11.4238L2.43718 6.95143C1.83675 6.65923 1.1204 6.75047 0.612554 7.18386C0.104711 7.61725 -0.0980905 8.31043 0.0960649 8.94922L6.47558 29.9345C6.69055 30.6417 7.34269 31.125 8.08179 31.125H34.9429C35.6819 31.125 36.3341 30.6417 36.549 29.9345L42.9285 8.94931C43.1228 8.31051 42.92 7.61733 42.4121 7.18394Z"/>
        </symbol>
        <symbol id="diamond" viewBox="0 0 25 22">
          <title>diamond</title>
          <path d="M6.53827 21.8597L0.425781 11.4503L6.53827 0.798828H18.3396L24.6942 11.4503L18.3396 21.8597H6.53827Z" stroke-width="1.5"/>
        </symbol>
      </defs>
    </svg>
    <!-- <svg preserveAspectRatio="xMidYMid meet">
      <use xlink:href="#arrow-left"/>
    </svg> -->
  </div>
</template>


