<template>
  <div class="levels u-row u-middle u-between u-inline-block">
    <template v-for="(level, index) in levels">
      <div class="levels__step u-relative">
        <svg class="u-full-width u-full-height" :class="index <= levelIndex ? 'u-fill-gold' : 'u-stroke-gold u-fill-transparent'" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#diamond"/>
        </svg>
        <div class="levels__step__title u-white t-p3" :class="{'t-bold': index <= levelIndex}">
          {{ level }}
        </div>
      </div>
      <div
        class="levels__net"
        v-if="index !== levels.length - 1"
        :style="{width: `calc(${(1/(levels.length-1)) * 100}% - ${12*(levels.length-1)}px)`}"
      />
    </template>
  </div>
</template>

<script>
import data from '@/assets/data'

export default {
  data () {
    return {
      levels: data.levels
    }
  },

  computed: {
    levelIndex () {
      return this.$store.getters['data/level']
    }
  },

  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.levels
  padding-bottom calc(15px + 1em)

  +mq($until: 'tablet')
    padding-left 7px
    padding-right 7px

  &__step
    width 25px
    height 20px

    &__title
      position absolute
      top calc(100% + 15px)
      width 100px
      left calc(50% - 50px)
      
      
  &__net
    height 1px
    background-color: $colors.gold
</style>