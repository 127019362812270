import Apollo from '@/store/interfaces/Apollo'
import gql from 'graphql-tag'
import { storage } from '@/utils/storage'

const apollo = new Apollo(process.env.VUE_APP_CRAFT_API, false)
apollo.setToken('Bearer ' + process.env.VUE_APP_CRAFT_TOKEN)
const graphqlClient = apollo.client

const site = process.env.VUE_APP_CRAFT_WEBSITE

class APICraft {

  getChapters () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchChapters {
            chapters: entries(site: "${site}", type: "stKeypoint", level: 1) {
              ... on stKeypoint_stKeypoint_Entry {
                id
                slug
                title
                image {
                  id
                  url
                }
                episodes: children(status: ["live", "disabled"]) {
                  ... on stKeypoint_stKeypoint_Entry {
                    id
                    uid
                    slug
                    title
                    enabled
                    subtitle: sousTitre
                    status
                    video {
                      id
                      url
                    }
                    image {
                      id
                      url
                    }
                    questions: stQuestions {
                      ... on stQuestions_questions_BlockType {
                        id
                        textWin
                        answers {
                          label
                          value
                        }
                        image: media {
                          id
                          url
                        }
                        title: titre
                      }
                    }
                  }
                }
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.chapters

        resolve(result)
        storage.setItem( `fetchChapters_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchChapters_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getEntries (ids) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchEntries ($id: [QueryArgument]) {
            entries (site: "${site}") {
              id
            }
          }
        `,
        variables: { id: ids }
      })
      .then(res => {
        const result = res.data.entries

        resolve(result)
        storage.setItem( `fetchEntries_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchEntries_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  imgSize ({id, width, height}) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      let query = {}

      if (width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int, $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true, height: $height)
                }
              }
            }
          `,
          variables: { id, width, height }
        }
      }
      else if (width && !height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true)
                }
              }
            }
          `,
          variables: { id, width }
        }
      }
      else if (!width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(height: $height, immediately: true)
                }
              }
            }
          `,
          variables: { id, height }
        }
      }
      else {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument])  {
              asset(id: $id) {
                ... on default_Asset {
                  url
                }
              }
            }
          `,
          variables: { id }
        }
      }

      graphqlClient.query(query)
        .then(res => {
          resolve(res.data.asset.url)

          const result = res.data.asset.url

          resolve(result)
          storage.setItem( `fetchImg_${JSON.stringify(args)}`, result)
        })
        .catch(err => {
          console.error('Error catching global params ', err)
        })
    })

    const cache = storage.getItem(`fetchImg_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }
}

export const api = new APICraft()