<template>
  <div class="homepage u-white">
    <header-homepage />
    <app-visible class="homepage__content u-row u-center">
      <div v-if="!$device.isMobile" class="u-w10of12 u-left homepage__name">
        Hello, {{ name }}
      </div>
      <template v-for="chapter in chapters" :key="chapter.slug">
        <chapter :data="chapter" />
      </template>
    </app-visible>
  </div>
</template>

<script>
import AppVisible from '@/components/common/AppVisible'
import HeaderHomepage from '@/components/HeaderHomepage'
import Chapter from '@/components/Chapter'

export default {
  computed: {
    chapters () {
      return this.$store.getters['data/chapters']
    },
    name () {
      return this.$store.getters['data/name']
    }
  },

  components: {
    HeaderHomepage,
    Chapter,
    AppVisible
  },

  created () {
    this.$store.dispatch('data/chapters')
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/mixins/viewport-sizer'

.homepage
  background-image url('/images/background--purple.jpg')
  background-size cover
  background-repeat repeat-y

  &__name
    font-family Phily
    font-weight: $weights.bold
    viewportSizer('font-size', 22, 1920, 12px, 27px)
    viewportSizer('line-height', 26, 1920, 17px, 30px)

  &__content
    margin-top 90px
    
    +mq($until: 'tablet')
      margin-top 30px
</style>