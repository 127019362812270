import { createWebHistory } from 'vue-router'
import { AuthenticationGuard } from 'vue-auth0-plugin'

import messages from './locales'

import Styleguide from '@/views/Styleguide'
import Introduction from '@/views/Introduction'
import Homepage from '@/views/Homepage'
import Episode from '@/views/Episode'
import HighScores from '@/views/HighScores'
import Price from '@/views/Price'

const routes = [
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: Styleguide,
    meta: {
      isFull: false
    }
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: Introduction,
    meta: {
      isFull: true
    }
  },
  {
    path: '/',
    name: 'Homepage',
    component: Homepage,
    meta: {
      isFull: false
    },
    beforeEnter: AuthenticationGuard
  },
  {
    path: '/:chapter/:episode',
    name: 'Episode',
    component: Episode,
    meta: {
      isFull: true
    },
    beforeEnter: AuthenticationGuard
  },
  {
    path: '/high-scores',
    name: 'HighScores',
    component: HighScores,
    meta: {
      isFull: false
    },
    beforeEnter: AuthenticationGuard
  },
  {
    path: '/price',
    name: 'Price',
    component: Price,
    meta: {
      isFull: true
    }
  }
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound', 
  //   component: NotFound
  // }
]

// let langRoutes = []

// for (let i = 0; i < routes.length; i++)  {
//   for (const locale of Object.keys(messages)) {
//     const prefix = locale === 'en' ? '' : locale + '/'
//     let route = {...routes[i]}
//     route.path = '/' + prefix + route.path
//     route.name = prefix + route.name
//     route.meta = {
//       ...routes[i].meta,
//       locale: locale
//     }

//     langRoutes.push(route)
//   }
// }

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: routes
}
