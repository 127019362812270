<template>
  <div class="price u-flex u-column u-between u-wrapper-panel u-pad-y-xl">
    <bubble v-for="i in 3" :bubble="false" :factor="20" url="/images/picto__star.png" class="price__picto" :class="'price__picto--' + i" />
    <bubble v-for="i in 3" :bubble="false" :factor="20" url="/images/picto__conf.png" class="price__picto" :class="'price__picto--' + (i + 3)" />
    <div class="u-full-width u-center u-relative">
      <div class="u-w1of12 u-w4of12--sm u-inline-block">
        <img class="u-full-width" src="/images/loreal_logo.svg">
      </div>
    </div>
    <div class="u-center t-c1 u-full-width">
      Congratulations!
    </div>
    <div class="u-full-width u-center u-row">
      <div class="u-bg-white u-radius u-w8of12 u-w10of12--sm u-row u-middle">
        <div class="u-w5of12 u-w12of12--sm u-center u-pad-y-lg u-pad-y-0--sm">
          <img class="price__img u-w12of12 u-w6of12--sm" src="/images/diploma.png">
        </div>
        <div class="u-w5of12 u-w12of12--sm u-center u-pad-y-lg u-pad-b-md--sm u-pad-t-0--sm">
          <div class="t-p1">
            {{ name }}
          </div>
          <div class="t-p2 u-marg-t-sm">
            You have been promoted to <span class="u-gold t-bold">{{ level }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="u-row u-center">
      <app-button :isGhost="true" :to="{name: 'Homepage'}" class="u-marg-r-md">
        Continue
      </app-button>
      <app-button :to="{name: 'HighScores'}">
        High scores
      </app-button>
    </div>
  </div>
</template>

<script>
import data from '@/assets/data'
import Bubble from '@/components/common/Bubble'

export default {
  components: {
    Bubble
  },

  computed: {
    name () {
      return 'bla'
      return this.$store.getters['data/name']
    },
    level () {
      return 'blabla'
      if (this.$store.getters['data/level'] >= data.levels.length) {
        return data.levels[data.levels.length - 1]
      }
      return data.levels[this.$store.getters['data/level']]
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.price
  background-image url('/images/background--yellow.jpg')
  background-size cover
  background-repeat repeat-y
  
  &__img
    transform translateX(-20%)

    +mq($until: 'tablet')
      transform translateX(0) translateY(-30%)
  
  &__picto
    position absolute !important
    width 110px
    
    &--1
      top 80%
      left 10%
      transform rotate(90deg)
    &--2
      top 65%
      left 90%
      transform rotate(120deg)
    &--3
      top 10%
      left 20%
    &--4
      top 15%
      left 85%
      transform rotate(90deg)
    &--5
      top 85%
      left 70%
      transform rotate(120deg)
    &--6
      top 30%
      left 5%
</style>