<template>
  <div class="header-hp">
    <div class="u-row u-between u-top u-pad-lg u-middle--sm u-pad-y-md--sm u-pad-x-sm--sm u-white">
      <app-visible v-if="!$device.isMobile" class="u-w3of12">
        <cartridge :to="{name: 'HighScores'}" svg="crown" :text="`${score} pts`" />
      </app-visible>
      <app-visible class="u-center u-w6of12 u-w9of12--sm u-left--sm">
        <div class="header-hp__title t-p1">
          Masterclass
        </div>
        <div class="header-hp__subtitle t-p1">
          L’OREAL HAIR SCIENCE
        </div>
      </app-visible>
      <app-visible class="u-flex u-middle u-w3of12 u-right">
        <cartridge v-if="!$device.isMobile" class="u-marg-r-sm" svg="student" :text="`${completed}/${total}`" />
        <app-button-round @click="onClose" :svg="$route.name === 'Homepage' ? 'out' : 'arrow-left'" />
      </app-visible>
    </div>
    <app-visible v-if="!$device.isMobile" class="u-row u-center u-full-width">
      <levels :progress="completed/total" class="u-w5of12" />
    </app-visible>
    <app-visible v-if="$device.isMobile" class="u-pad-x-sm">
      <div class="homepage__name u-left">
        Hello, {{ name }}
      </div>
      <div class="header-hp__mobile u-row u-between u-marg-t-xs">
        <cartridge :to="{name: 'HighScores'}" svg="crown" :text="`${score} pts`" />
        <cartridge svg="student" :text="`${completed}/${total}`" />
      </div>
    </app-visible>
    <app-visible v-if="$device.isMobile && $route.name === 'Homepage'" class="u-row u-center u-full-width u-pad-x-sm u-marg-t-sm">
      <levels :progress="completed/total" class="u-full-width" />
    </app-visible>
  </div>
</template>

<script>
import Cartridge from '@/components/common/Cartridge'
import AppButtonRound from '@/components/common/AppButtonRound'
import AppVisible from '@/components/common/AppVisible'
import Levels from '@/components/Levels'

export default {
  components: {
    Cartridge,
    AppButtonRound,
    Levels,
    AppVisible
  },

  inject: ['auth'],

  computed: {
    name () {
      return this.$store.getters['data/name']
    },
    total () {
      return this.$store.getters['data/episodesTotal']
    },
    completed () {
      return this.$store.getters['data/episodesCompleted']
    },
    score () {
      return this.$store.getters['data/score']
    }
  },

  methods: {
    onClose () {
      if (this.$route.name === 'Homepage') {
        this.auth.logout()
      } else {
        this.$router.push({name: 'Homepage'})
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/mixins/mq'

.header-hp
  &__mobile
    border-radius 10px
    background: $gradients.yellow
    padding 18px 20px

  &__title
    viewportSizer('font-size', 85, 1920)
    line-height normal

    +mq($until: 'desktop')
      viewportSizer('font-size', 85, 1440)

    +mq($until: 'tablet')
      viewportSizer('font-size', 70, 767)

  &__subtitle
    margin-top .8em
    letter-spacing .14em
    viewportSizer('font-size', 18, 1920)
    line-height normal

    +mq($until: 'desktop')
      viewportSizer('font-size', 24, 1440)

    +mq($until: 'tablet')
      margin-top .2em
      viewportSizer('font-size', 24, 767)
</style>