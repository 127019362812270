
<template>
  <button
    class="t-button u-cursor-pointer u-center t-p2"
    :class="{
      'is-ghost': isGhost,
      'is-gold': isGold
    }"
  >
    <template v-if="url !== ''" >
      <a v-if="isUrlAbsolute" :href="url" target="_blank" class="u-relative" :class="metaClass">
        <slot/>
      </a>
      <router-link v-else :to="url" class="u-relative" :class="metaClass">
        <slot/>
      </router-link>
    </template>
    <router-link v-else-if="to.name" :to="to" class="u-relative" :class="metaClass">
      <slot/>
    </router-link>
    <span v-else class="u-relative" :class="metaClass">
      <slot/>
    </span>
  </button>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  computed: {
    metaClass () {
      let classs =  this.icon ? 'u-row u-center u-middle' : ''

      classs += this.isGhost ? ' t-bold--md ' : ''

      return classs + ' u-relative t-p2 ' + this.innerClass
    }
  },

  props: {
    icon: {
      type: Boolean,
      default: false
    },
    isGhost: {
      type: Boolean,
      default: false
    },
    isGold: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    innerClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.t-button
  position relative
  padding: clamp(10px, .5em, 20px) clamp(20px, 1em, 40px)
  color: $colors.black
  background-color transparent
  z-index 0
  
  &.is-small
    padding: clamp(5px, .5em, 10px) clamp(10px, 1.5em, 20px) clamp(7px, .7em, 12px)

  &:after
    content ''
    z-index -1
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    border-radius 100px
    background-color: $colors.white
    transition: transform .5s ease-in-out-quart
    box-shadow: inset 0px -11px 18px -6px rgba($colors.black, .3)

  &.is-gold
    color: $colors.black

    &:after
      background-color: $colors.gold
      box-shadow: inset 0px -11px 18px -6px rgba($colors.white, .7)

  &.is-ghost
    color: $colors.white

    &:after
      border: solid 2px $colors.white
      background-color: transparent
      box-shadow none
  
  +mq($from: 'tablet')
    &:hover
      &:after
        transform scale(1.1, 1.1)

</style>