<template>
  <div
    id="main"
    ref="main"
    @mousemove="onMouseMove"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
  >
    <symbols-svg />
    <router-view v-slot="{ Component }">
      <transition name="t-fader--router">
        <component class="view" :is="Component" />
      </transition>
    </router-view>
    <!-- {{ auth }} -->
  </div>
</template>

<script>

import App from './App'

import SymbolsSvg from '@/components/SymbolsSvg'

export default {
  name: 'App',

  inject: ['auth'],

  mixins: [App],

  components: {
    SymbolsSvg
  },

  created () {
    this.needPrice = false
    this.unwatch = this.$store.watch((state, getters) => getters['data/level'],
      (newVal, oldVal) => {
        if (oldVal === -1) {
          return
        }
        else {
          this.needPrice = true
          // this.$router.push({name: 'Price'})
        }
      }
    )
  },

  watch: {
    $route () {
      if (this.needPrice) {
        this.needPrice = false
        this.$router.push({name: 'Price'})
      }
    }
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'
// @import '~@/styles/settings/mixins/mq'

#main
  min-height 100vh

  &.is-full
    position fixed
    top .2px
    bottom .2px
    left 0
    right 0
    overflow hidden
    min-height auto

.view
  min-height 100vh

</style>