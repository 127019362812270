<template>
  <div class="bubble u-inline-block u-relative">
    <div class="u-box-1by1">
      <div ref="bubble" class="bubble__img u-wrapper-panel">
        <app-image class="u-full-height u-full-width u-fit-contain" v-if="id" :id="id" />
        <img class="u-full-height u-full-width u-fit-contain" v-else :src="url" />
      </div>
      <img ref="img" v-if="bubble" class="u-block u-wrapper-panel" src="/images/bubble.png">
    </div>
  </div>
</template>

<script>
import Simplex from 'perlin-simplex'

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    factor: {
      type: Number,
      default: 10
    },
    bubble: {
      type: Boolean,
      default: true
    }
  },

  created () {
    this.simplex = new Simplex()
    this.seed = Math.random()
  },

  methods: {
    onUpdate ({ elapsed }) {
      this.$refs.bubble.style.transform = `translate3d(
        ${this.simplex.noise(this.seed + 0, elapsed * .0002) * this.factor}px,
        ${this.simplex.noise(this.seed + 1, elapsed * .0002) * this.factor}px,
      0)`
      if (this.bubble) {
        this.$refs.img.style.transform = `translate3d(
          ${this.simplex.noise(this.seed + 2, elapsed * .0002) * this.factor}px,
          ${this.simplex.noise(this.seed + 3, elapsed * .0002) * this.factor}px,
        0)`
      }
    }
  }
}

</script>

<style lang="stylus">
.bubble
  &__img
    padding 15%
</style>